import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import stakeIcon from '../../assets/images/stake.png';


import { useSelector } from 'react-redux';
import './audit.scss';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import utils from '@okwallet/extension/src/utils';

const claimContractAbi = require('../../helper/presale.json')

const vestingContractAbi = require('../../helper/vesting.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Mint() {

  const web3provider = useSelector(state => state.web3Provider);
  const address = useSelector(state => state.address);
  //const address = "0x047862412AF18dA4c549549630887dbA1AF6C0F2"

  useEffect(async() => {
    //Runs only on the first render
    if(web3provider){
    }else{
    }
  }, [web3provider]);




  return (
    <>
    <motion.section 
    {...paragraphAnimation}
    id='audit' className='audit'>
      <div className='audit__content'>
        <div className='flex-audit-container'>
          <h1>ALPHA SYNDICATE</h1>
          <h3>AUDIT REPORT</h3>
          <div className='audit-round-header'>ROUND #1</div>

          <div className='audit-gap'></div>

          <div className='audit-field-entry'>
            <div className='audit-field-1'>Total# of Deposits</div>
            <div className='audit-field-2'>52</div>
          </div>

          <div className='audit-field-entry'>
            <div className='audit-field-1'>Total Deposit (S):</div>
            <div className='audit-field-2'>$87,090.00</div>
          </div>

          <div className='audit-field-entry'>
            <div className='audit-field-1'># of Invalid Deposits:</div>
            <div className='audit-field-2'>1</div>
          </div>

          <div className='audit-field-entry'>
            <div className='audit-field-1'>Max. Allocation ($):</div>
            <div className='audit-field-2'>100,000</div>
          </div>

          <div className='audit-field-entry'>
            <div className='audit-field-1'>Allocation Used (%):</div>
            <div className='audit-field-2'>87%</div>
          </div>


          <div className='audit-round-header-bottom'>FULL AUDIT COMPLETED BY ALPHA GOVERNANCE TEAM</div>

        </div>

        {/* <div className='audit-footer'>alphashards.com</div> */}
      </div>
    </motion.section>
    </>
  );
}
